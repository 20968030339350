/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderFulfillmentRangeType } from './orderFulfillmentRangeType';


/**
 * Represents an inclusive range during which the server will allow the order to be marked shipped or delivered.
 */
export interface FulfillmentRange { 
    fromTS: string;
    fromType: OrderFulfillmentRangeType;
    toTS: string;
    toType: OrderFulfillmentRangeType;
}
export namespace FulfillmentRange {
}


