/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PendingIntegraReviewDtls } from './pendingIntegraReviewDtls';
import { ClosedDtls } from './closedDtls';
import { NeedsAttentionDtls } from './needsAttentionDtls';
import { DeliveredDtls } from './deliveredDtls';
import { FutureDOS } from './futureDOS';
import { ShippedDtls } from './shippedDtls';
import { CancelDtls } from './cancelDtls';
import { RejectDtls } from './rejectDtls';


export interface OrderStatusChg { 
    orderId: number;
    orderStatus: string;
    readonly orderStatusDesc?: string;
    readonly deliveryTS?: string;
    futureDOS?: FutureDOS;
    rejectDtls?: RejectDtls;
    pendingIntegraReviewDtls?: PendingIntegraReviewDtls;
    cancelDtls?: CancelDtls;
    requestedCancelDtls?: CancelDtls;
    needsAttentionDtls?: NeedsAttentionDtls;
    shippedDtls?: ShippedDtls;
    deliveredDtls?: DeliveredDtls;
    closedDtls?: ClosedDtls;
}

