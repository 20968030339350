/**
 * Order Management
 * Order Service.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderTemplate } from './orderTemplate';
import { OrderSchedule } from './orderSchedule';
import { OrderStatusDetails } from './orderStatusDetails';
import { OrderDiagCode } from './orderDiagCode';
import { OrderItem } from './orderItem';
import { OrderRef } from './orderRef';
import { OrderProvider } from './orderProvider';


export interface Order { 
    statusAge?: number | null;
    orderId?: number;
    readonly payerLobId?: number;
    readonly payerPlanId?: number;
    readonly payerAcctId?: number;
    readonly payerFamilyName?: string;
    readonly payerAcctName?: string;
    readonly payerPlanName?: string;
    readonly payerLobName?: string;
    readonly mbrId?: number;
    altSysId?: string | null;
    expOrderDeliveryTS?: string;
    orderNum?: string;
    readonly orderSysType?: string;
    authCode?: string | null;
    authWinFromDt?: string;
    authWinToDt?: string;
    staIndvAddressID?: number;
    staLine1: string;
    staLine2?: string | null;
    staCity: string;
    staState: string;
    staZip: string;
    staRelToIndv: string;
    staAddresseeName?: string | null;
    staTypeCode: string;
    mbrHasRef: boolean;
    isMedicarePrimOrd?: boolean;
    orderDlvInstr?: string;
    addtnlCmnt?: string;
    orderProvider?: OrderProvider;
    careNavUserId?: number | null;
    readonly careNavUserFullName?: string;
    orderItems?: Array<OrderItem>;
    orderDiagCodes?: Array<OrderDiagCode>;
    orderRefs?: Array<OrderRef>;
    orderSchedule?: OrderSchedule;
    orderTemplate?: OrderTemplate;
    orderStatus?: OrderStatusDetails;
    prevOrderStatuses?: Array<OrderStatusDetails>;
    readonly isLateDlvry?: boolean;
    readonly isSigRequired?: boolean;
    readonly dlvMethodCode?: string | null;
    readonly isTrans?: boolean;
    transId?: number;
    schedFreqCode?: string | null;
    lastServiceDt?: string | null;
    baseDeliveryTS?: string;
    readonly isMigrated?: boolean;
    readonly createdBy?: string;
    readonly createdTs?: string;
    readonly modifiedTs?: string;
    readonly modifiedBy?: string;
}

